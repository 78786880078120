import React, { useState } from 'react';
import styles from '../styles/foundingmember.module.css';


function AdhesionContent() {

    return (

        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Moroccan Society of Digital Transformation</h1>
                <h1>الجمعية المغربية للتحول الرقمي</h1>
            </header>
            <text>
                <p>
                    Membership: The association consists of members classified as follows:
                    Honorary members and adhering members.
                </p>
                <p>
                    Requirements for Membership in the association :
                    <li> The member must hold at least a doctorate, an engineering diploma, a recognized diploma or equivalent, and be an active
                        member and perform his duties in an official capacity, whether in the public sector or the private sector.</li>
                    <li> Obtaining office approval</li>
                    <li> Commitment to pay the annual subscription amount</li>
                </p>
            </text>

        </div>

    );
}

export default AdhesionContent;
