import { BrowserRouter, Routes, Route } from "react-router-dom";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend"; // Import the backend you want to use

import Home from './pages/home';
import About from './pages/about';
import FoundingMember from './pages/foundingmember';
import Adhesion from './pages/adhesion';
import Contact from './pages/contact';

function App() {
  return (
    <DndProvider backend={HTML5Backend}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/members" element={<FoundingMember />} />
          <Route path="/adhesion" element={<Adhesion />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </DndProvider>
  );
}

export default App;

