import React from 'react';

import Navbar from "../components/navbar.js";
import SideNavbar from "../components/sidebar.js";


import ContactContent from "./contactcontent.js";


function Contact() {
    return (
        <div >
            <Navbar />
            <SideNavbar />
            <ContactContent/>            
        </div>
    )
}
export default Contact;