import React, { Component } from "react";
import styles from '../styles/navbar.module.css';
import logo from '../assets/logo/Logo-SMTD-25pc.png'
class Navbar extends Component {
    render() {
        return (
            <div className={styles.topnav}>
                <a className={styles.split} ></a>
                <div >
                    <b href="/" ><img src={logo}/></b> 
                    <a class="active" href="/">Home</a>
                    <a href="./about">About SMTD</a>
                    <a href="./members">Founding members</a>
                    <a href="./adhesion">Adhesion</a>
                    <a href="./contact">Contact</a>
                </div>
            </div>
        )
    }
}

export default Navbar;