import React, { Component } from "react";
import styles from '../styles/sidebar.module.css';

class SideNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeLink: "home", // Set the default active link
        };
    }

    handleLinkClick = (link) => {
        this.setState({ activeLink: link });
    };

    render() {
        const { activeLink } = this.state;

        return (
            <div className={styles.sidebar}>
                <a
                    className={activeLink === "home" ? styles.active : ""}
                    onClick={() => this.handleLinkClick("home")}
                    href="/"
                >
                    Home
                </a>                <a
                    className={activeLink === "news" ? styles.active : ""}
                    onClick={() => this.handleLinkClick("news")}
                    href="/news"
                >
                    News
                </a>
                <a
                    className={activeLink === "events" ? styles.active : ""}
                    onClick={() => this.handleLinkClick("events")}
                    href="/events"
                >
                    Events
                </a>
                <a
                    className={activeLink === "publications" ? styles.active : ""}
                    onClick={() => this.handleLinkClick("publications")}
                    href="/publications"
                >
                    Publications
                </a>
            </div>
        );
    }
}

export default SideNavbar;
