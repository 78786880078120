import React, { useState } from 'react';
import styles from '../styles/foundingmember.module.css';


function AdhesionContent() {

    return (

        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Moroccan Society of Digital Transformation</h1>
                <h1>الجمعية المغربية للتحول الرقمي</h1>
            </header>
            <text>
                <p>
                    Pr. Rabia MARGHOUBI
                </p>
                <p>
                    National Institut of Posts and Telecommunications
                </p>
                <p>
                    Email : <a href='mailto:contact@madit.ma'> contact@madit.ma</a>
                </p>
            </text>
        </div>

    );
}

export default AdhesionContent;
