import React, { useState } from 'react';
import styles from '../styles/about.module.css';


function Aboutcontent() {

    return (

        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Moroccan Society of Digital Transformation</h1>
                <h1>الجمعية المغربية للتحول الرقمي</h1>
            </header>
            <text>
                <p>The Moroccan Society of Digital Transformation is an association created in 2021, Its vocation is to develop the pillars of digital
                    transformation in Morocco, through training and applied scientific research activities. Its head office is located at the Rabat School
                    of Mines.</p>

                <p>
                    The objectives of the association can be summarized as follows:
                    <li> Develop new maturity models, strategies, and business models for digital transformation.</li>
                    <li> Develop methodologies, reference models, and guidelines for digital transformation. </li>
                    <li> Develop innovative systems based on digital transformation techniques.</li>
                    <li> Identify new initiatives to accelerate digital transformation. </li>
                    <li>Develop new models for change managing to success a new project of digital transformation.</li>
                    <li>Develop scientific research interested in development in the field of digital transformation.</li>
                    <li>Organize seminars, lectures, and scientific presentations and publish in specialized journals to raise community awareness,
                        develop and suit the training and continuous training.</li>
                    <li>Organize seminars, lectures, and scientific presentations and publish in specialized journals to raise community awareness,
                        develop and suit the training and continuous training.</li>
                    <li>Exchange coordination, and cooperation at the national and international levels with associations and competent authorities that
                        have the same objectives.</li>
                    <li>Issue bulletins for communication between all interested parties and work to publish research in specialized scientific journals.</li>
                    <li>Involve university researchers, industrialists, local groups, competent agencies, and all activities that can help in achieving the
                        goals of the association.</li>
                </p>
            </text>

        </div>

    );
}

export default Aboutcontent;
