import React from 'react';

import Navbar from "../components/navbar.js";
import SideNavbar from "../components/sidebar.js";


import Aboutcontent from "./aboutcontent.js";
import styles from '../styles/app.module.css';



function About() {
    return (
        <div >
            <Navbar />
            <SideNavbar />
            <Aboutcontent/>            
        </div>




    )
}
export default About;