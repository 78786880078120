import React, { useState } from 'react';
import styles from '../styles/foundingmember.module.css';


function FoundingMemberContent() {

    return (

        <div className={styles.container}>
            <header className={styles.header}>
                <h1>Moroccan Society of Digital Transformation</h1>
                <h1>الجمعية المغربية للتحول الرقمي</h1>
            </header>
            <text>
                <p>
                    Founding members :
                    <li> <a href='https://www.researchgate.net/profile/Rabia-Marghoubi'> Pr. Rabia MARGHOUBI</a>, National Institute of Posts and Telecommunications</li>
                    <li> <a href='https://www.researchgate.net/profile/Azedine-Boulmakoul'> Pr. Azedine BOULMAKOUL</a> Faculty of Sciences and Techniques of Mohammedia </li>
                    <li> <a href='https://www.researchgate.net/profile/Nissrine-Souissi'>Pr. Nissrine SOUISSI</a>, Rabat School of Mines </li>
                    <li> <a href='https://www.researchgate.net/profile/Fadwa-Zaoui'>Mme Fadwa ZAOUI</a>, BANK AL-MAGHRIB </li>
                    <li> <a href='https://www.researchgate.net/profile/Mina-El-Maallam'>Pr. Mina LEMAALEM</a>, School of Information Sciences</li>
                </p>
            </text>

        </div>

    );
}

export default FoundingMemberContent;
