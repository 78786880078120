import React from 'react';

import Navbar from "../components/navbar.js";
import SideNavbar from "../components/sidebar.js";
import styles from "../styles/home.module.css";

function Home() {
    return (
        <div>
            <Navbar />
            <SideNavbar />
            <div className={styles.container}>
                <header className={styles.header}>
                    <h1>Moroccan Society of Digital Transformation</h1>
                    <h1>الجمعية المغربية للتحول الرقمي</h1>
                </header>

                <section className={styles.intro}>
                    <p>
                        Welcome to the Moroccan Society of Digital Transformation website.
                        This website provides you with information about the association, its members and various topics.
                    </p>
                </section>


                <footer className={styles.footer}>
                    <p>&copy; 2024 MADIT</p>
                </footer>

            </div>
        </div>
    )
}
export default Home;